import { useTranslation } from "@equiem/web-ng-lib";
import { Site, stringNotEmpty, Theme, Viewer } from "@equiem/web-ng-lib";
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useContext } from "react";
import { useCallback } from "react";
import { ParentLink } from "./ParentLink";

interface Props {
  className?: string;
}

export const MenuLinks: React.FC<Props> = ({ className = "" }) => {
  const { t } = useTranslation();

  const site = useContext(Site);
  const viewer = useContext(Viewer);
  const { breakpoints } = useContext(Theme);
  const { asPath } = useRouter();
  const nsnx = "?ns=right_nav&nx=1";

  const subject =
    viewer?.profile?.email != null
      ? encodeURIComponent(
          `${t("main.webFeedback")} - ${site.name} - ${viewer.profile.email}`,
        )
      : encodeURIComponent(`${t("main.webFeedback")} - ${site.name}`);
  const mailTo = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      window.open(
        `mailto:${site.email ?? "support@getequiem.com"}?subject=${subject}`,
        "mail",
      );
    },
    [site.email, subject],
  );

  return (
    <div className={`menu-links ${className}`}>
      <Link href={`/${nsnx}`} passHref>
        <a className={classNames({ active: /\/$/.test(asPath) })}>
          {t("main.home")}
        </a>
      </Link>
      <Link href={`/news${nsnx}`} passHref>
        <a className={classNames({ active: /\/news(\/.+)?/.test(asPath) })}>
          {t("main.news")}
        </a>
      </Link>
      <Link href={`/events${nsnx}`} passHref>
        <a
          className={classNames({
            active: /\/(events|event\/.+)/.test(asPath),
          })}
        >
          {t("main.events")}
        </a>
      </Link>
      {site.buildingInfo.categories[0] == null ? (
        <Link href={`/info${nsnx}`} passHref>
          <a className={classNames({ active: /\/info(\?.+)?$/.test(asPath) })}>
            {site.buildingInfo.title}
          </a>
        </Link>
      ) : (
        <ParentLink
          title={site.buildingInfo.title}
          openInit={/\/info(\/.+)?/.test(asPath)}
        >
          {site.buildingInfo.categories.map((c, i) => (
            <Link key={i} href={`/info-category/${c.slug}${nsnx}`} passHref>
              <a
                className={`small text-capitalize ${classNames({
                  active: new RegExp(`/info-category/${c.slug}`).test(asPath),
                })}`}
              >
                {c.name}
              </a>
            </Link>
          ))}
          <Link href={`/info${nsnx}`} passHref>
            <a
              className={`small ${classNames({
                active: /\/info(\?.+)?$/.test(asPath),
              })}`}
            >
              {t("main.all")}
            </a>
          </Link>
        </ParentLink>
      )}
      {site.featureModules.bookings.enabled &&
      site.featureModules.bookings.enableMenu !== false ? (
        <Link href={`/bookings${nsnx}`} passHref>
          <a
            className={classNames({ active: /\/bookings(\/.+)?/.test(asPath) })}
          >
            {t("main.bookings")}
          </a>
        </Link>
      ) : null}
      {site.store.navEnabled ? (
        <Link href={`/store${nsnx}`} passHref>
          {/* Do not remove store-link class name. It's used in external automated tests */}
          <a
            id="store-link"
            className={classNames({ active: /\/store(\/.+)?/.test(asPath) })}
          >
            {site.store.title}
          </a>
        </Link>
      ) : null}
      {site.featureModules.visitors.enabled &&
      site.featureModules.visitors.enableMenu !== false ? (
        <Link href={`/visitor-management${nsnx}`} passHref>
          <a
            className={classNames({
              active: /\/visitor-management(\/.+)?/.test(asPath),
            })}
          >
            {t("main.visitorAppointments")}
          </a>
        </Link>
      ) : null}
      {site.featureModules.requests.enabled &&
      site.featureModules.requests.enableMenu !== false ? (
        <Link href={`/request-management${nsnx}`} passHref>
          <a
            className={classNames({
              active: /\/request-management(\/.+)?/.test(asPath),
            })}
          >
            {t("main.requestManagement")}
          </a>
        </Link>
      ) : null}
      {stringNotEmpty(site.email) ? (
        <a
          onClick={mailTo}
          target="_blank"
          href={`mailto:${
            site.email ?? "support@getequiem.com"
          }?subject=${subject}`}
        >
          {t("main.contactUs")}
        </a>
      ) : null}
      {viewer != null ? (
        <Link href={`/profile${nsnx}`} passHref>
          <a
            className={classNames({ active: /\/profile(\/.+)?/.test(asPath) })}
          >
            {t("main.myAccount")}
          </a>
        </Link>
      ) : null}
      <style jsx>{`
        .menu-links {
          padding: 32px;
        }
        a {
          color: ${site.primaryContrastColour};
          display: block;
          margin: 0 0 18px;
          width: 100%;
          opacity: 0.7;
          transition: 0.25s ease-in-out;
          font-size: 18px;
        }
        a.small {
          margin: 0 0 12px;
          font-size: 12px;
        }
        a:hover,
        a.active {
          opacity: 1;
          text-decoration: none;
        }
        @media (min-width: ${breakpoints.md}px) {
          a {
            margin: 0 0 24px;
            font-size: 24px;
          }
          a.small {
            margin: 0 0 16px;
            font-size: 16px;
          }
        }
      `}</style>
    </div>
  );
}
