import { Site } from "@equiem/web-ng-lib";
import { useContext } from "react";

export const useLinktype = () => {
  const { domainAliases } = useContext(Site);

  const linkType = (url: string): "internal" | "dynamic" | "external" => {
    const match = url.match(/^(https?:)?\/\/([^\/]+)/);

    if (match == null || match[2] == null || match[2] === window.location.host || domainAliases.includes(match[2])) {
      return "internal";
    }

    const domainName = match[2];
    if (domainName === "equiem.page.link" || domainName.endsWith("equiem.mobi")) {
      return "dynamic";
    }

    return "external";
  };

  const isExternalLink = (url: string) => linkType(url) === "external";

  return {
    isExternalLink,
    linkType,
  }
};
